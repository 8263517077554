export const scoringConfig = {
  lobbySize: { mismatchPenalty: 20 },
  gameType: { mismatchPenalty: 20 },
  onlineEconomy: { mismatchPenalty: 20 },
  devTeamSize: { mismatchPenalty: 20 },
  manyEntities: { mismatchPenalty: 20 },
  playerInteractionLevel: { mismatchPenalty: 40 }
};

export const criteriaCombinationConfig = {
  default: 'union', 

   devTeamSize: 'intersection',
   playerInteractionLevel: 'overwrite',
   lobbySize: 'intersection',
   manyEntities: 'overwrite',
   onlineEconomy: 'intersection',
   gameType: 'union'
};

export const prohibitedPairings = [
  ["Deterministic Lockstep", "Rollback"],
  ["Deterministic Lockstep", "Server Side Rewind"],
  ["Rollback", "Server Side Rewind"],
  ["Rollback", "Interest Management"],
  ["Deterministic Lockstep", "Interest Management"]
];
export const networkProfiles = [
  {
    name: "Client-Server with Server-Side Authority",
    description: "A network model where the server has full control and authority over the game state.",
    criteria: {
      lobbySize: ["Small", "Medium", "Large"],
      manyEntities: false,
      gameType: "Competitive",
      onlineEconomy: [true, false],
      playerInteractionLevel: ["Collision", "CombatNormal"],
      recommendLibraryIfSmallTeam: true,
      devTeamSize: ["Medium", "Large"]
    }
  },
  {
    name: "Client-Server with Client-Side Authority (Relay)",
    description: "A hybrid network model where the server relays data but decisions and authority lie with the client.",
    criteria: {
      lobbySize: ["Small", "Medium", "Large"],
      manyEntities: false,
      gameType: ["Casual", "Cooperative"],
      onlineEconomy: false,
      playerInteractionLevel: ["None", "CombatNormal"],
      recommendLibraryIfSmallTeam: false,
      devTeamSize: ["Small", "Medium", "Large"]
    }
  },
  {
    name: "Client-Server with Hybrid Authority",
    description: "Some aspects of the game are server-authoritative, and some are client-authoritative",
    criteria: {
      lobbySize: ["Small", "Medium", "Large"],
      manyEntities: false,
      gameType: "Competitive",
      onlineEconomy: [true, false],
      playerInteractionLevel: ["None", "Collision", "CombatNormal"],
      recommendLibraryIfSmallTeam: false,
      devTeamSize: ["Small", "Medium", "Large"]
    }
  },
  {
    name: "Peer to Peer",
    description: "A network model where players communicate directly without an intermediary server.",
    criteria: {
      lobbySize: ["Small"],
      manyEntities: false,
      gameType: ["Casual", "Cooperative"],
      onlineEconomy: false,
      playerInteractionLevel: ["None", "Collision", "CombatNormal"],
      recommendLibraryIfSmallTeam: true,
      devTeamSize: ["Medium", "Large"]
    }
  }
];

export const networkAlgorithms = [
  {
    name: "Server-Side Rewind",
    description: "A technique common in FPS games. The server keeps a record of recent game state; when a player shoots, the server considers their latency and checks if the player would have hit an opponent based on what they currently saw. While this sometimes leads to an opponent feeling like they died after taking cover, it's a tradeoff most FPS games make as it reduces perceived latency for the shooter..",
    criteria: {
      playerInteractionLevel: "CombatInstant",
      devTeamSize: ["Medium", "Large"],
    },
    allowedNetworkProfiles: ["Client-Server with Server-Side Authority"],
    level: 1,
  },
  {
    name: "Rollback",
    description: "A technique common in Fighting games such as Tekken. A player client predicts opponent actions based on their previous action, and updates their game state if the received input was different. This creates a a fair competitive environment where player input and timing is respected - at the cost of an occasional state update when a prediction was incorrect.",
    criteria: {
      lobbySize: ["Small"],
      gameType: "Competitive",
      recommendLibraryIfSmallTeam: true,
      devTeamSize: ["Medium", "Large"],
      playerInteractionLevel: "CombatResponsive",
      manyEntities: false
    },
    allowedNetworkProfiles: ["Peer to Peer", "Client-Server with Client-Side Authority (Relay)"],
    level: 1,
  },
  {
    name: "Deterministic Lockstep",
    description: "A technique common in Real-Time Strategy games. Requiring a deterministic simulation, this technique allows the efficient syncronisation of a high amount of entities by sending only player inputs rather than entity data.",
    criteria: {
      manyEntities: true,
      lobbySize: ["Small"],
      gameType: "Competitive",
      recommendLibraryIfSmallTeam: true,
      devTeamSize: ["Medium", "Large"],
      playerInteractionLevel: ["None", "Collision", "CombatNormal"]
    },
    allowedNetworkProfiles: ["Peer to Peer", "Client-Server with Client-Side Authority (Relay)"],
    level: 1,
  },
  {
    name: "Interest Management",
    description: "Optimizes network usage by only sending relevant data to each player, beneficial for larger lobbies.",
    criteria: {
      lobbySize: ["Medium", "Large"]
    },
    allowedNetworkProfiles: [
      "Client-Server with Server-Side Authority", 
      "Client-Server with Client-Side Authority (Relay)", 
      "Client-Server with Hybrid Authority", 
      "Peer to Peer"
    ],
    level: 2,
  },
  {
    name: "Third Party Library",
    description: "Utilizes external libraries to speed up development, particularly advantageous for small development teams.",
    criteria: {
      devTeamSize: ["Small"]
    },
    allowedNetworkProfiles: ["Client-Server with Server-Side Authority", "Peer to Peer"],
    other: true,
    level: 2,
  }
];

